import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { deepCopy } from '../../../../shared/deepcopy';
import { PagedResponse } from '../../../../models/server/PagedResponse';
import { ValidationProblem } from '../../../../models/server/Problem';
import { Query } from '../../../../models/server/Query';
import { Table } from '../../../../models/server/Table';
import { DataSourceItem } from '../../../../models/view/DataSourceItem';
import { DataManagerService } from '../../../../services/datamanager.service';
import { ErrorHandlerService, ErrorType } from '../../../../services/errorhandler.service';
import { ActiveDataSourceService } from '../../../services/active-data-source.service';
import { IDataViewModel, ITableQueryPredicate } from '../../../services/query';
import { MatTableDataSource } from '@angular/material/table';
import { AuthorisationDirective } from '../../../../authentication/authorisation.directive';
import { FormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout/flex';
import { MaterialModule } from '../../../../material.module';

interface FilterColumnDef {
  name: string;
  id: string;
  isSystem: boolean;
}

@Component({
  selector: 'app-edit-query',
  templateUrl: './edit-query.component.html',
  styleUrls: ['./edit-query.component.scss'],
  standalone: true,
  imports: [MaterialModule, FlexModule, FormsModule, AuthorisationDirective]
})
export class EditQueryComponent implements OnInit {

  /**
   * A query whose parameters are to be modified by this component. If not passed,
   * then a new query is created upon it being saved.
   */
  @Input()
  public query?: IDataViewModel<Query>;

  /**
   * Shorthand to determine whether this component is editing a pre-existing query
   * or creating a new one.
   */
  get isEditingQuery() {
    return !!this.query;
  }

  /**
   * Emitted upon the changes to the query either being saved or discarded, triggered
   * by the 'Save' and 'Cancel' buttons respectively.
   */
  @Output()
  public finishedEditing: EventEmitter<{ saved: boolean }> = new EventEmitter();

  /**
   * A reference to the name of the query being edited or created. This is needed as in
   * the latter case, `this.query` will be `null` and as such its name can't be bound to.
   */
  public queryName: string;

  /**
   * Can operators use/view this query?
   */
  public allowOperator: boolean;

  /**
   * All columns of the respective table that this query can filter
   */
  public allColumns: FilterColumnDef[] = [];

  public joinPossible: boolean;
  public join: boolean;
  public joinName: string;
  public joinToQueryID: string;
  public joinDataSources: Array<DataSourceItem>;
  public joinParentField: string;
  public joinParentFields: Array<string>;
  public joinChildField: string;
  public joinChildFields: Array<string>;
  public joinMustMatch: boolean;
  public usedAlready: boolean = false;

  private tables: Table[];
  private queries: Query[];

  /**
   * A model of the columns actively being filtered by this query.
   *
   * @description Changes from the UI are bound the `new` property, and existing values
   * (i.e. column names and filter values from before the edit operation began) are bound
   * to the `current` property. If the changes are to be discarded, every `new` property
   * becomes instead its respective `current` property as to discard changes that were bound
   * to the model.
   *
   * Likewise, if changes are to be saved, the the `current` properties are set
   * to the corresponding `new` values, in order to provide immediate feedback on the UI and
   * negate the need to load the new query from the API server upon changed being saved.
   */
  public queryColumns = new MatTableDataSource<ITableQueryPredicate>();

  public errorMessages = new Map<string, string>();

  constructor(
    public dataSource: ActiveDataSourceService,
    public dataManagerService: DataManagerService,
    public changeDetectorRef: ChangeDetectorRef,
    private errorHandler: ErrorHandlerService) { }

  ngOnInit() {
    // Load the table columns as these are required for determining what can be filtered.
    this.dataSource.table.pipe(
      map(table => (table ? table.columns : []).map(column => column.name))
    ).subscribe({
      next:
        columns => {
          this.allColumns = [];
          columns.map(c => this.allColumns.push({ id: c, name: this.colName(c), isSystem: c.startsWith('__') }));
          this.joinParentFields = this.allColumns.filter(c => !c.isSystem).map(c => c.name);
        }
    });

    // If a model query was passed, then bind a copy of its predicates to those on the UI.
    if (this.query) {
      this.queryName = this.query.name;
      this.allowOperator = this.query.allowOperator;
      // Make sure any mutable filter values (i.e. those which are asked at runtime) are
      // converted to a corresponding '?' entry.
      this.queryColumns.data = this.query.predicates.map(predicate => ({
        ...predicate, value: {
          new: predicate.isImmutable ? predicate.value.new : '?',
          current: predicate.value.current
        }
      }));

      this.joinPossible = false;
      this.joinName = this.query.dataSource.joinName;
      this.joinToQueryID = this.query.dataSource.joinToQueryID;
      this.joinParentField = this.query.dataSource.joinParentField;
      this.joinChildField = this.query.dataSource.joinChildField;
      this.joinMustMatch = this.query.dataSource.joinMustMatch;

    }

    // this.dataManagerService.getAllQueries().subscribe(queries => {
    //   this.joinQueries = queries.filter(q => !q.joinToQueryID && q.id != this.query?.id);
    //   this.usedAlready = queries.filter(q => q.joinToQueryID == this.query?.id).length > 0;
    //   // cant do a join if there is nothing to join to or the current query is already used in a join as a child
    //   this.joinPossible = this.joinQueries?.length > 0 && !this.usedAlready;
    //   this.join = this.joinPossible && !!this.query.dataSource.joinToQueryID;
    //   this.getChildFields(this.joinToQueryID);
    // })

    this.dataManagerService.getTableList(0, 1000)
      .pipe(
        switchMap((tables: PagedResponse<Table>) => {
          this.tables = tables.values;
          return this.dataManagerService.getAllQueries();
        }),
        switchMap((queries: Query[]) => {
          return this.queries = queries;
        }))
      .subscribe({
        next: queries => {
          this.buildDataSources();
        }
      });

  }

  private colName(name: string): string {
    if (name === '__created') {
      return 'Updated Date';
    }
    if (name === '__updatedbylogin') {
      return 'Updated By';
    }

    return name;
  }

  /**
   * Creates a new column on the data model based on the next column which isn't already
   * presently being filtered by this query. If all columns are acting as filters, this
   * method shouldn't be called (i.e. the callee button should be disabled).
   */
  newColumn() {
    const columns = [...this.queryColumns.data];
    const validColumnNames = this.allColumns.filter(col =>
      !this.queryColumns.data.map(q => q.key).includes(col.id)
    );

    // Should always be at least one column left, or else the button will be disabled
    // Since this represents a new column to filter against, only the `new` property is needed.
    columns.push({
      key: validColumnNames[0].id,
      value: { current: '', new: '' },
      isImmutable: false
    });

    this.queryColumns.data = columns;
  }

  /**
   * Removes all occurrences of a given column name from the query data model. If there are
   * multiple predicates acting on a given column, they will all be removed.
   * @param column The column and its predicate to remove from the model.
   */
  remove(column?: ITableQueryPredicate) {
    this.queryColumns.data = this.queryColumns.data.filter(
      col => col.key !== column.key
    );
  }

  /**
   * Called when the user has finished creating or updating a query. If the query doesn't exist
   * and this component doesn't refer to a pre-existing query, then it will be sent to the API to
   * be created. Otherwise, if this component is attempting to update an existing query, then
   * it will be asynchronously updated on the server.
   *
   * @param params An object containing a `save` property, indicating whether the changes should
   * persist and be sent to the server.
   */
  finished(params: { save: boolean }) {
    // If a query was initially passed, update its predicates appropriately
    if (params.save) {
      // Define a new query model in the event of one not previously existing.
      const NEW_QUERY_MODEL = <IDataViewModel<Query>>{
        name: '', predicates: [], isValid: true,
        isRunning: false, isEditing: false, active: true, dataSource: new Query()
      };

      // Update the query if it exists, or else now create a new one.
      const referencedQuery = this.query || NEW_QUERY_MODEL;

      referencedQuery.name = this.queryName;
      referencedQuery.allowOperator = this.allowOperator;
      referencedQuery.predicates = this.queryColumns.data.map(params => {
        const isImmutable = !params.value.new.startsWith('?'),
          predicateValue = isImmutable ? params.value.new : '';

        // Otherwise the query will run without required runtime params
        if (!isImmutable) {
          referencedQuery.isValid = false;
        }

        return {
          key: params.key,
          value: { current: predicateValue, new: predicateValue },
          isImmutable
        };
      });

      if (this.join) {
        referencedQuery.dataSource.joinName = this.joinName;
        referencedQuery.dataSource.joinToQueryID = this.joinToQueryID;
        referencedQuery.dataSource.joinParentField = this.joinParentField;
        referencedQuery.dataSource.joinChildField = this.joinChildField;
        referencedQuery.dataSource.joinMustMatch = this.joinMustMatch;
      } else {
        referencedQuery.dataSource.joinName = null;
        referencedQuery.dataSource.joinToQueryID = null;
        referencedQuery.dataSource.joinParentField = null;
        referencedQuery.dataSource.joinChildField = null;
        referencedQuery.dataSource.joinMustMatch = null;
      }

      // Add the query object to the local model in the event of creating a new one, this
      // stops the entire list from needing to be downloaded again.
      this.errorMessages = new Map<string, string>();
      this.dataSource.save(
        deepCopy(referencedQuery),
        { shouldCreate: !this.query }
      ).subscribe({
        next: _ => {
          this.reset();
          this.changeDetectorRef.detectChanges();
          this.finishedEditing.emit({ saved: params.save });
        },
        error:
          async err => {
            let errorData = await this.errorHandler.handleErr(err, 'Query', true);
            if (errorData.type == ErrorType.Validation) {
              let valErrors = <ValidationProblem>errorData.problem;
              if (valErrors) {
                for (let fieldName in valErrors) {
                  this.errorMessages.set(fieldName, valErrors[fieldName].errors[0].errorMessage);
                }
              }
            }
          }
      });

    } else {
      this.finishedEditing.emit({ saved: params.save });
    }
  }

  /**
   * Determines if any of the query parameters (or its name) have changed from the existing
   * model. If they have, then the save button can be enabled.
   */
  get isQueryValid(): boolean {
    const data = this.queryColumns.data;

    // Empty query names are not allowed nor are empty predicates
    if (!this.queryName || data.some(({ value }) => !value.new)) {
      return false;
    }

    if (this.join) {
      if (!this.joinToQueryID || !this.joinParentField || !this.joinChildField) {
        return false;
      }
    }

    if (this.join != (!!this.query?.dataSource.joinToQueryID) ||
      this.joinName !== this.query?.dataSource.joinName ||
      this.joinToQueryID !== this.query?.dataSource.joinToQueryID ||
      this.joinParentField !== this.query?.dataSource.joinParentField ||
      this.joinChildField !== this.query?.dataSource.joinChildField ||
      this.joinMustMatch !== this.query?.dataSource.joinMustMatch) {
      return true;
    }

    // If the query is a new query, or a new filter column is being added, then this means
    // that the query has changed and therefore can be saved.
    if (!this.query || data.length !== this.query.predicates.length) {
      return true;
    }

    // Alternatively, if only existing column names/values have been changed, make sure there
    // is a difference between the old value and the new value.
    const parametersChanged = !Object.values(data).reduce(
      (previous, model, index) => {
        const { key, value } = this.query.predicates[index];
        const currentFilterValue = model.isImmutable ? value.current : '?';
        return previous && currentFilterValue === model.value.new && key === model.key;
      }, true
    );

    // Make sure the query name is defined and is different
    const nameChanged = this.queryName !== this.query.name;
    return this.queryName.length > 0 && (parametersChanged || nameChanged || this.query.allowOperator !== this.allowOperator);
  }

  reset() {
    this.queryName = '';
    this.query = null;
    this.queryColumns.data = [];
  }

  userColumns(): FilterColumnDef[] {
    return this.allColumns.filter(c => !c.isSystem);
  }

  systemColumns(): FilterColumnDef[] {
    return this.allColumns.filter(c => c.isSystem);
  }

  async joinQueryChanged(ev: any) {
    this.getChildFields(ev.target.value);
  }

  private buildDataSources(): void {

    this.joinDataSources = [];

    // sort the tables
    this.tables.sort((t1: Table, t2: Table) => {
      return t1.name.localeCompare(t2.name);
    });

    // get all the queries for each table, sorted
    for (let t of this.tables) {
      let tableQueries = this.queries.filter(q => q.tableID === t.id && !q.joinToQueryID && q.id != this.query?.id);
      this.usedAlready = this.queries.filter(q => q.joinToQueryID == this.query?.id).length > 0;

      if (tableQueries) {
        tableQueries.sort((q1: Query, q2: Query) => {
          if (q1.id === t.defaultQuery) {
            return -1;
          }
          if (q2.id === t.defaultQuery) {
            return 1;
          }
          return q1.name.localeCompare(q2.name);
        });

        for (let q of tableQueries) {
          this.joinDataSources.push(new DataSourceItem(t, q));
        }
      }
    }

    //    this.joinQueries = this.queries.filter(q => !q.joinToQueryID && q.id != this.query?.id);
    // cant do a join if there is nothing to join to or the current query is already used in a join as a child
    this.joinPossible = this.joinDataSources?.length > 0 && !this.usedAlready;
    this.join = this.joinPossible && !!this.query.dataSource.joinToQueryID;
    this.getChildFields(this.joinToQueryID);

  }

  private getChildFields(qid: string): void {
    let ds = this.joinDataSources?.find(ds => ds.query.id == this.joinToQueryID);
    if (ds) {
      this.joinChildFields = ds.query.columns.filter(c => !c.name.startsWith('_')).map(c => c.name);
    } else {
      this.joinChildFields = null;
    }
  }

}
