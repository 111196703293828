import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { SafePipe } from '../services/safe';
import { FlexModule } from '@angular/flex-layout/flex';
import { DialogTitleComponent } from './dialog-title/dialog-title.component';
import { MaterialModule } from '../material.module';

export class ConfirmationOptions {
  title: string = 'Confirm';
  question: string;
  yesAnswer: string = 'Yes';
  noAnswer: string = 'No';
  response: Boolean;

  constructor(question: string, title?: string, yes?: string, no?: string) {
    this.question = question;
    if (title) {
      this.title = title;
    }
    if (yes) {
      this.yesAnswer = yes;
    }
    if (no) {
      this.noAnswer = no;
    }
  }
}

@Component({
    template: `

  <app-dialog-title [title]="data.title" icon="question_mark"></app-dialog-title>

  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5em" style="margin-top:10px">
      <div [innerHtml]="data.question | safe:'html'" style="margin:16px 0 16px!important;font-size:1.2em" > </div>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button data-cy="confirm-no-button" type="button" mat-raised-button (click)="goAhead(false)"><mat-icon>cancel</mat-icon> {{data.noAnswer}}</button>
    <button data-cy="confirm-yes-button" type="button" mat-raised-button color="primary" (click)="goAhead(true)"><mat-icon>done</mat-icon> {{data.yesAnswer}}</button>
  </div>
  `,
    standalone: true,
  imports: [MaterialModule, DialogTitleComponent, FlexModule, SafePipe]
})
export class ConfirmationDialogComponent {
  title = 'Confirm';

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationOptions) {
  }

  goAhead(response: boolean) {
    this.data.response = response;
    this.dialogRef.close(this.data);
  }
}
