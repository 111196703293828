@if (!dataSource.queries.length && !dataSource.isLoading && table) {
<p>No queries saved for table
  <span>“{{ table?.name }}”</span>
</p>
}
<mat-accordion displayMode="flat">
  @for (query of dataSource.queries; track query) {
  <mat-expansion-panel [(expanded)]="query.active" class="mat-elevation-z1">
    <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
      <mat-panel-title>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
            {{ query.isDefault ? 'Default' : query.name }}
            @if (query.isSystem) {
            <mat-icon>lock</mat-icon>
            }
          </div>
          @if ((dataSource.activeQuery | async)?.id == query.id) {
          <mat-chip-listbox selectable="false">
            <mat-chip-option color="accent" highlighted="true"
              title="The results of this query are displayed on the left-hand table">
              Active
            </mat-chip-option>
          </mat-chip-listbox>
          }
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    @if (!query.isEditing) {
    @if (!query.predicates.length) {
    <p class="no-background">
      No column filters are defined for this query
    </p>
    } @else {
    <table>
      <thead>
        <tr>
          <td>Column</td>
          <td>Filter Value</td>
        </tr>
      </thead>
      <tbody>
        @for (entry of query.predicates; track entry) {
        <tr>
          <td>{{ getColumnName(entry.key) }}</td>
          <td>
            @if (!entry.isImmutable) {
            <input type="text" class="noMatFormControl"
              title="Enter a specific value to filter this column by, or enter an asterisk (*) to see all values for this column."
              [(ngModel)]="entry.value.new">
            } @else {
            <span>{{ entry.value.new }}</span>
            }
          </td>
        </tr>
        }
      </tbody>
    </table>
    }
    @if (query | isQueryValid: (dataSource.activeQuery | async); as queryState) {
    <mat-action-row>
      @switch (queryState.error?.reason) {
      @case ('isRunning') {
      <button mat-button color="primary"
        title="Re-run this query, loading any changes that may have occurred since its last run"
        (click)="dataSource.reloadData.emit()">
        <mat-icon>refresh</mat-icon>
        Refresh
      </button>
      }
      @default {
      <button mat-button (click)="run(query)" [class.running]="query.isRunning" [disabled]="!queryState.canRun"
        [title]="queryState.error ? queryState.error.message : 'Run query'" color="primary">
        @switch (query.isRunning) {
        @case (true) {
        <mat-spinner diameter="20"></mat-spinner>
        }
        @default {
        <ng-container>
          <mat-icon>play_arrow</mat-icon>
          Run
        </ng-container>
        }
        }
      </button>
      }
      }
      @if (!query.isSystem) {
      <button mat-button require="DatamanUpdateQuery" title="Make changes to this query" activeorg
        (click)="query.isEditing = true">
        <mat-icon>edit</mat-icon>
        Edit
      </button>
      }
    </mat-action-row>
    }
    } @else {
    <app-edit-query [query]="query" (finishedEditing)="query.isEditing = false"></app-edit-query>
    }
  </mat-expansion-panel>
  }
  @if (userCanViewAll) {
  <mat-expansion-panel activeorg [expanded]="dataSource.isDefiningNewQuery | async">
    <mat-expansion-panel-header collapsedHeight="20px" expandedHeight="20px">
      <mat-panel-title>
        <em>Create New Query</em>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-edit-query></app-edit-query>
  </mat-expansion-panel>
  }
</mat-accordion>
<!-- *ngIf="(dataSource.isDefiningNewQuery | async) == true" -->