import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExternalFile, ExternalFileResponse, ExternalFileTypeEnum } from "../../models/server/ExternalFile";
import { FileService } from "../../services/file.service";
import { LimitsService } from "../../services/limits.service";
import { EventHubService } from "../../services/event-hub.service";
import { Resources } from "../../models/server/Constants";
import { LimitExceededEvent } from "../../models/LimitExceededEvent";
import { FileDNDDirective } from '../fileDND.directive';
import { SimpleProgressComponent } from '../simpleprogress.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { MaterialModule } from '../../material.module';

const DEFAULT_DROP_CAPTION = 'Drop file or click here to upload';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  standalone: true,
  imports: [MaterialModule, FlexModule, SimpleProgressComponent, FileDNDDirective]
})
export class UploadFileComponent implements OnInit, OnDestroy {

  error: string;
  selectedFile: any;
  progressPercentage: number;
  uploading: boolean;
  // overNotOk: boolean;
  acceptableFiles: string;


  dropCaption = DEFAULT_DROP_CAPTION;

  MAX_FILE_SIZE = 2; // MB

  @Input()
  autoUpload: boolean = true;

  @Input()
  disabled: boolean = false;

  @Input()
  fileType: ExternalFileTypeEnum;

  @Input()
  conversion: (files: FileList | Array<File>) => Observable<ExternalFileTypeEnum | null>;

  @Output()
  selected = new EventEmitter<any>();

  @Output()
  uploaded = new EventEmitter<ExternalFileResponse>();

  private fileInputElement: ElementRef;
  // private offerConvert: boolean = false;
  protected readonly ExternalFileTypeEnum = ExternalFileTypeEnum;

  @ViewChild("fileElem", { static: false }) set fileInput(fi: ElementRef) {
    if (fi) {
      this.fileInputElement = fi;
    }
  };

  private imgSub: Subscription;


  constructor(
    private fileService: FileService,
    private limitService: LimitsService,
    private eventHubService: EventHubService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<UploadFileComponent>
  ) {
    // this.dialogRef?.keydownEvents().subscribe(event => {
    //   if (event.key === "Escape") {
    //     this.dialogRef.close();
    //   }
    // });

    this.imgSub = this.eventHubService.PasteImage.subscribe(file => this.handleFiles([file]));
  }

  ngOnInit() {
    let at = [];
    let mt = ExternalFile.ExternalFileMimeTypes.get(this.fileType);
    if (mt)
      for (let v of mt)
        at = at.concat(v);
    this.acceptableFiles = at.join(',');
  }

  ngOnDestroy() {
    if (this.imgSub) {
      this.imgSub.unsubscribe();
      this.imgSub = null;
    }
  }

  filesPicked(ev: any) {
    const element = ev.currentTarget as HTMLInputElement;
    this.filesSelected(element?.files);
  }

  filesDropped(ev: DragEvent) {
    this.filesSelected(ev.dataTransfer.files);
  }

  private filesSelected(files: FileList) {
    if (files.length > 1) {
      this.dropCaption = `${files.length} selected`;
    } else if (files.length === 1) {
      this.dropCaption = `${files[0].name}`;
    }

    this.selected.emit(files);

    if (this.autoUpload) {
      this.handleFiles(files);
    }
  }

  private handleFiles(files: FileList | Array<File>): void {
    if (!this.disabled) {
      if (this.checkFiles(files)) {
        if (this.conversion) {
          this.conversion(files).subscribe(convertTo => this.uploadFiles(files, convertTo));
        } else {
          this.uploadFiles(files, null);
        }

        // if (this.offerConvert) {
        //   const opt = new ConfirmationOptions('Would you like to convert the file to an image?', 'Convert to Image', 'Yes', 'No, just upload');
        //   this.dialog.open(ConfirmationDialogComponent, {data: opt})
        //     .afterClosed()
        //     .subscribe(resp => {
        //       this.uploadFiles(files, resp.response);
        //     });

      } else {
        this.uploadFiles(files, null);
      }
    }
  }

  private uploadFiles(files: FileList | Array<File>, convertTo: ExternalFileTypeEnum | null): void {

    const file = files[0];

    try {

      this.error = null;
      this.progressPercentage = 0;
      this.uploading = true;

      const exFileType = ExternalFile.GetTypeFromMimeType(file.type);

      this.limitService.checkResourceLimit(Resources.Files, false, false)
        .pipe(
          switchMap((ev: LimitExceededEvent) => this.fileService.uploadFile(file, file.name, exFileType, convertTo))
        )
        .subscribe({
          next:
            ev => {
              if (ev.type === HttpEventType.UploadProgress) {
                this.progressPercentage = Math.floor(ev.loaded * 100 / ev.total);
              } else if (ev.type === HttpEventType.Response) {
                const uploadedFile = new ExternalFileResponse(ev.body[0]);
                this.uploading = false;
                this.uploaded.emit(uploadedFile);
                this.dropCaption = 'Uploaded ' + this.dropCaption;
                setTimeout(() => this.dropCaption = DEFAULT_DROP_CAPTION, 5000);
              }
            },
          error:
            err => {
              this.error = 'Upload failed';
              this.uploading = false;
              this.dropCaption = DEFAULT_DROP_CAPTION;
            }
        });

    } catch (error) {
      this.uploading = false;
      this.error = 'Sorry, the upload failed. Please try again.';
    } finally {

    }
  }

  checkFiles(files: any): boolean {
    if (files && files.length === 1) {

      const file = files[0];

      let mt = ExternalFile.ExternalFileMimeTypes.get(this.fileType);
      if (!mt || mt.indexOf(file.type) < 0) {
        this.error = 'Incorrect file type';
        return false;
      }

      if (file.size > (this.MAX_FILE_SIZE * 1024 * 1024)) {
        this.error = `The maximum file size is ${this.MAX_FILE_SIZE}MB`;
        return false;
      }
    } else {
      this.error = 'Select a single file';
      return false;
    }

    return true;
  }

  handleError(error: string) {
    this.error = error;
  }

  open() {
    this.fileInputElement.nativeElement.click();
  }

}

