import { Component, Inject } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from '../../models/server/User';

import { UserInvite } from '../../models/server/UserInvite';
import { ErrorData, ErrorHandlerService } from '../../services/errorhandler.service';
import { UserService } from '../../services/user.service';
import { ConfirmationDialogComponent, ConfirmationOptions } from '../confirmation.component';
import { MDatePipe } from '../../services/mdate.pipe';
import { WarningPanelComponent } from '../warning-panel/warning-panel.component';
import { SimpleProgressComponent } from '../simpleprogress.component';
import { DialogTitleComponent } from '../dialog-title/dialog-title.component';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-invitecheck.dialog',
  templateUrl: './invitecheck.dialog.component.html',
  standalone: true,
  imports: [MaterialModule, DialogTitleComponent, SimpleProgressComponent, WarningPanelComponent, MDatePipe]
})
export class InvitecheckDialogComponent {

  processing = false;
  error: ErrorData;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<InvitecheckDialogComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public invite: UserInvite,
    private errorHandler: ErrorHandlerService
  ) {
    if (this.dialogRef) {
      this.dialogRef?.keydownEvents().subscribe(event => {
        if (event.key === "Escape") {
          this.dialogRef.close();
        }
      });
    }
  }


  accept(): void {
    this.error = null;
    const cdlg = this.dialog.open(ConfirmationDialogComponent, {
      data: new ConfirmationOptions(`If you continue, your current organisation will be closed
      and all it's labels and other data will be unavailable.

      After accepting, you will need to login again.`,
        'Are you sure?')
    });

    cdlg.afterClosed().pipe(
      switchMap((yes: ConfirmationOptions) => {
        if (yes?.response) {
          this.processing = true;
          return this.userService.acceptInvite(this.invite.code);
        }
        return of(null);

      })).subscribe((user: User) => {
        if (user) {
          this.processing = false;
          this.dialogRef.close('accepted');
        }
      },
        async err => {
          this.processing = false;
          this.error = await this.errorHandler.handleErr(err, 'Invite', true);
        });
  }

  decline(): void {
    this.error = null;
    this.processing = true;
    this.userService.deleteInvite(this.invite.code)
      .subscribe(
        o => {
          this.processing = false;
          this.dialogRef.close('declined');
        },
        async err => {
          this.processing = false;
          this.error = await this.errorHandler.handleErr(err, 'Invite', true);
        });

  }

}
