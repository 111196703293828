import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OrganisationService } from '../services/organisation.service';
import { Observable } from 'rxjs';
import { Organisation } from '../models/server/Organisation';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageKeys } from '../models/server/Constants';
import { RecentActivity, RecentActivityKind, RecentActivityService } from '../services/recent-activity.service';
import { NewsService } from '../services/news.service';
import { AddSamplesDialogComponent } from '../comps/add-samples-dialog/add-samples-dialog.component';
import { User } from "../models/server/User";
import { UserService } from "../services/user.service";
import { SafePipe } from '../services/safe';
import { AsyncPipe, DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FlexModule } from '@angular/flex-layout/flex';
import { MaterialModule } from '../material.module';


@Component({
  selector: 'app-home-start',
  templateUrl: './home-start.component.html',
  standalone: true,
  imports: [MaterialModule, FlexModule, RouterLink, AsyncPipe, DatePipe, SafePipe]
})
export class HomeStartComponent implements OnInit {

  startPageUrl: SafeResourceUrl;
  // useNewHomePage: Observable<boolean>;
  showAtStartup = localStorage.getItem(LocalStorageKeys.ShowHomeAtStart) === 'true';
  recentActivity: Array<RecentActivity>;
  newsContent: Observable<string>;

  @Input()
  isOrgActivate: boolean;

  organisation: Organisation;
  user: User;

  constructor(
    private sanitizer: DomSanitizer,
    public organisationService: OrganisationService,
    private userService: UserService,
    private recentActivityService: RecentActivityService,
    private newsService: NewsService,
    private dialog: MatDialog,
  ) {
    this.startPageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.startPageUrl);
    this.newsContent = this.newsService.getNews();
    this.organisationService.getOrganisation().subscribe(org => this.organisation = org);
    this.userService.getUser().subscribe(u => this.user = u);

    // this.useNewHomePage = organisationService.getOrganisationFlags().pipe(
    //   switchMap(flags => {
    //     return of(flags?.findIndex(f => f === 'UseNewHomePage') >= 0);
    //   }));

  }

  ngOnInit(): void {
    this.recentActivity = this.recentActivityService.list();
  }

  addSamples(): void {
    this.dialog.open(AddSamplesDialogComponent, { data: this.organisation });
  }

  toggleShowAtStartup() {
    this.showAtStartup = !this.showAtStartup;
    localStorage.setItem(LocalStorageKeys.ShowHomeAtStart, this.showAtStartup.toString());
  }

  getIcon(kind: RecentActivityKind): string {
    switch (kind) {
      case RecentActivityKind.Console: return 'dashboard';
      case RecentActivityKind.Label: return 'receipt';
      case RecentActivityKind.Table: return 'view_list';
    }
    return '';
  }

  // getLink(ra : RecentActivity) : string {
  //
  //   let url: string = ra.url;
  //   switch(ra.kind) {
  //     case RecentActivityKind.Table :
  //       url = '/data';
  //       break;
  //   }
  //   return url;
  // }
  //
  // getParams(ra : RecentActivity) : any {
  //
  //   let params = null;
  //
  //   switch (ra.kind) {
  //     case RecentActivityKind.Table :
  //       params = ra.params;
  //       break;
  //   }
  //
  //   return params;
  // }
}
