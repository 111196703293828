import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatMenu } from "@angular/material/menu";
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-toolbar-icon-button',
  templateUrl: './toolbar-icon-button.component.html',
  styleUrls: ['./toolbar-icon-button.component.scss'],
  standalone: true,
  imports: [MaterialModule, FlexModule, ExtendedModule]
})
export class ToolbarIconButtonComponent implements OnInit {

  @Input()
  title: string = '';

  @Input()
  caption: string;

  @Input()
  icon: string;

  @Input()
  iconIsSvg: boolean;

  @Output()
  clickHandler = new EventEmitter<any>();

  @Input()
  disabled: boolean;

  @Input()
  color: string = '';

  @Input()
  menu: MatMenu;

  @Input()
  raised = false;

  extraStyle: string = '';

  ngOnInit() {
    if (!this.caption) {
      this.extraStyle += 'width:42px;';
    }
  }


  handleClick($event: any): void {
    this.clickHandler.emit($event);
  }
}
