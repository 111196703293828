import { Component, Input } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { RouterLink } from '@angular/router';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-login-buttons',
  templateUrl: './login-buttons.component.html',
  styleUrls: ['./login-buttons.component.scss'],
  standalone: true,
  imports: [MaterialModule, ExtendedModule, RouterLink]
})
export class LoginButtonsComponent {

  @Input()
  showRegister: boolean;

  @Input()
  showLogin: boolean;

  constructor(private auth: AuthenticationService) { }

  logout(): void {
    this.auth.logout();
  }

}
