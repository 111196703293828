import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LimitExceededEvent } from '../../models/LimitExceededEvent';
import { UserService } from '../../services/user.service';
import { User } from '../../models/server/User';
import { RouterLink } from '@angular/router';
import { DialogTitleComponent } from '../dialog-title/dialog-title.component';
import { MaterialModule } from '../../material.module';

// interface IDataParam {
//   event: LimitExceededEvent;
//   showOk: boolean;
// }

@Component({
  selector: 'app-limit-exceeded-dialog',
  templateUrl: './limit-exceeded-dialog.component.html',
  styleUrls: ['./limit-exceeded-dialog.component.scss'],
  standalone: true,
  imports: [MaterialModule, DialogTitleComponent, RouterLink]
})
export class LimitExceededDialogComponent implements OnInit {
  user: User;

  constructor(
    private userService: UserService,
    private dialogRef: MatDialogRef<LimitExceededDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: LimitExceededEvent
  ) {
    if (this.dialogRef) {
      this.dialogRef?.keydownEvents().subscribe(event => {
        if (event.key === "Escape") {
          this.dialogRef.close();
        }
      });
    }

    // if (!data.showOk) {
    //   // auto close
    //   setTimeout(() => this.dialogRef.close(this.data), 15000);
    // }
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe(u => this.user = u);
    // if (!this.data.showOk) {
    //   // auto close
    //   setTimeout(() => this.dialogRef.close(this.data), 15000);
    // }
  }

  viewSubscriptions() {

  }

  goAhead(): void {
    this.data.confirmed = true;
    this.dialogRef.close(this.data);
  }

}
