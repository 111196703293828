import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { Observable, of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import * as v from '../version';
import { AboutDialogComponent } from './about.component';
import { AuthenticationService } from './authentication/authentication.service';
import { FeedbackDialogComponent } from './comps/feedback/feedback.component';
import { LocalStorageKeys } from './models/server/Constants';
import { Organisation } from './models/server/Organisation';
import { Roles, User } from './models/server/User';
import { ErrorHandlerService } from './services/errorhandler.service';
import { HelpService } from './services/help.service';
import { LimitsService } from './services/limits.service';
import { OrganisationService } from './services/organisation.service';
import { UserService } from './services/user.service';
import { UserProfileDialogComponent } from './settings/dialogs/userprofiledialog/userprofiledialog.component';
import { LoadingService, LoadingState } from "./services/loading.service";
import { MessageService } from "./services/message.service";
import { AsyncPipe } from '@angular/common';
import { EventHubComponent } from './comps/event-hub/event-hub.component';
import { HelpPanelComponent } from './comps/help-panel/help-panel.component';
import { NotificationsComponent } from './comps/notifications/notifications.component';
import { ErrorHandlerComponent } from './errorhandler.component';
import { PlanExpiryComponent } from './comps/plan-expiry/plan-expiry.component';
import { UpdateNotificationComponent } from './comps/update-notification/update-notification.component';
import { LimitAlertComponent } from './comps/limit-alert/limit-alert.component';
import { InvitecheckComponent } from './comps/invitecheck/invitecheck.component';
import { LoginButtonsComponent } from './authentication/login-buttons/login-buttons.component';
import { SimpleProgressComponent } from './comps/simpleprogress.component';
import { OrgNotDeactivatedDirective } from './services/activeOrg.directive';
import { AuthorisationDirective } from './authentication/authorisation.directive';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { MaterialModule } from './material.module';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    MaterialModule,
    FlexModule,
    ExtendedModule,
    RouterLink,
    AuthorisationDirective,
    RouterLinkActive,
    OrgNotDeactivatedDirective,
    SimpleProgressComponent,
    LoginButtonsComponent,
    InvitecheckComponent,
    LimitAlertComponent,
    UpdateNotificationComponent,
    PlanExpiryComponent,
    RouterOutlet,
    ErrorHandlerComponent,
    NotificationsComponent,
    HelpPanelComponent,
    EventHubComponent,
    AsyncPipe]
})
export class AppComponent
  implements OnInit, OnDestroy {

  versionString: string;
  organisation: Organisation;
  userRecord: User;
  consoleOnly = false;
  busy = false;
  busyMessage: string;
  loading = true;

  private limitTimer: Observable<number>;
  private limitTimerSub: Subscription;
  private loadingSub: Subscription;
  private authSub: Subscription;
  private authed: Boolean;
  orgUpdatedSub: Subscription;

  // get canShowSettings(): boolean {
  //   return this.userRecord && (this.userRecord.role === Roles.Subscriber || this.userRecord.role === Roles.Admin);
  // }

  constructor(
    public authService: AuthenticationService,
    private userService: UserService,
    private organisationService: OrganisationService,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private helpService: HelpService,
    private errHandler: ErrorHandlerService,
    private logger: NGXLogger,
    private router: Router,
    private loadingService: LoadingService,
    private messageService: MessageService,
    limitService: LimitsService) {

    // globally prevent context menu
    if (environment.production) {
      window.addEventListener("contextmenu", e => e.preventDefault());
    }

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      let url = event.urlAfterRedirects;
      if (url.indexOf('?')) {
        url = url.substring(0, url.indexOf('?'));
      }
    });

    this.loadingSub = this.loadingService.change
      .pipe(
        filter(state => state.owner === 'app')
      )
      .subscribe((val: LoadingState) => {
        if (val.owner) {
          this.busy = val.isLoading;
          this.busyMessage = val.message;
        }
      });

    this.versionString = v.version.version;
    this.loadIcons();

    /* TURN OFF LIMIT CHECK

    let firstTime = true;
    this.limitTimer = timer(5 * 1000, 10 * 1000 * 60 * 12);  // 5 seconds, then every 12 hours
    this.limitTimerSub = this.limitTimer.pipe(
      switchMap(x => this.authService.isAuthenticated),
      switchMap(auth => {
        if (auth) {
          return limitService.checkResourceLimit(null, false); // use firsTime to show at startup
        } else {
          return of(null);
        }
      })
    ).subscribe(() => {
      firstTime = false;
    });
    */
  }

  ngOnInit(): void {

    this.orgUpdatedSub = this.organisationService.orgUpdate.subscribe((org: Organisation) => {
      if (org) {
        this.organisation = org;
      }
    });

    this.authSub = this.authService.isAuthenticated.pipe(
      switchMap(a => {
        this.authed = a;

        return (this.authed ? this.authService.getToken() : of(null));
      }),
      switchMap(token => {
        if (token) {
          this.logger.updateConfig({
            level: NgxLoggerLevel.INFO,
            customHttpHeaders: new HttpHeaders({ Authorization: `Bearer ${token}` })
          });
        } else {
          this.logger.updateConfig({
            level: NgxLoggerLevel.INFO,
            customHttpHeaders: new HttpHeaders({ Authorization: `Anonymous` })
          });
        }

        return (this.authed ? this.userService.getUser() : of(null));
      }),
      switchMap((user: User | null) => {
        this.userRecord = user;
        if (user) {

          //this.consoleOnly = user.role === Roles.Operator;

          return this.organisationService.getOrganisation();
        } else {
          return of(null);
        }
      })
    ).subscribe(
      {
        next: org => {
          if (this.organisation != org) {
            this.organisation = org;

            if (this.organisation) {

              this.messageService.connect(environment.msgApiUrl, this.userRecord.id, this.userRecord.organisationID); // 'ws://localhost:8080'

              if (!this.organisation.isActivated) {
                // org not activated - must do this first
                //this.showOrganisationSettings(this.userRecord?.isSubscriber);
                this.router.navigateByUrl('/settings/organisation');
              } else if (localStorage.getItem(LocalStorageKeys.ReopenAddOn)) {
                // returning after attaching addon
                this.router.navigateByUrl('/settings/addons');

              } else {
                // redirect to the last open page only if this is the first hit
                const showHome = localStorage.getItem(LocalStorageKeys.ShowHomeAtStart) === 'true' || this.organisation.isTrialExpired || this.organisation.isDeactivated;

                if (!showHome) {
                  const lp = localStorage.getItem(LocalStorageKeys.LastPage);

                  if (!lp) {
                    if (this.userRecord?.role === Roles.Operator) {
                      this.router.navigateByUrl('/console');
                    } else {
                      this.router.navigateByUrl('/design');
                    }
                  } else if (!window.location.pathname.startsWith(lp)) {
                    this.router.navigateByUrl(lp);
                  }
                } else {
                  this.router.navigateByUrl('/');
                }
              }
            }
          }
          this.loading = false;
        },
        error: async err => {
          this.loading = false;
          await this.errHandler.handleErr(err);
        }
      }
    );
  }

  goto(anchor: string): void {
    window.location.href = anchor;
  }

  ngOnDestroy(): void {

    this.limitTimerSub?.unsubscribe();
    this.limitTimerSub = null;
    this.limitTimer = null;

    this.authSub?.unsubscribe();
    this.authSub = null;

    this.loadingSub?.unsubscribe();
    this.loadingSub = null;
  }

  about() {
    this.dialog.open(AboutDialogComponent);
  }

  feedback(): void {
    this.dialog.open(FeedbackDialogComponent);
  }

  showUserProfile() {
    this.dialog.open(UserProfileDialogComponent);
  }

  // showOrganisationSettings(userIsSubscriber?: boolean) {
  //   if (!this.consoleOnly) {
  //     this.dialog.open(SettingsDialogComponent,
  //       {
  //         data: userIsSubscriber || false,
  //         height: '750px'
  //       });
  //   }
  // }

  // showOrganisationFonts(userIsSubscriber?: boolean) {
  //   if (!this.consoleOnly) {
  //     this.dialog.open(FontSettingsDialogComponent,
  //       {
  //         data: !!userIsSubscriber,
  //         // height: '750px'
  //       });
  //   }
  // }
  //
  // showPrinters(): void {
  //   this.dialog.open(ManagePrintersDialogComponent,
  //     {
  //       // height: '560px',
  //       width: '900px'
  //     });
  // }
  //
  // showSerials(): void {
  //   this.dialog.open(SerialNumberListDialogComponent);
  // }
  //
  // showVariables(): void {
  //
  //   this.dialog.open(VariablesDialogComponent, {
  //     data: {
  //       labelView: null,
  //       selectMode: false,
  //       allowClear: false,
  //       selected: null,
  //       editGlobal: true
  //     }
  //   })
  // }
  //
  // showAddons(): void {
  //   this.dialog.open(ManageAddOnsDialogComponent);
  // }


  showHelpPage(pageId: string): void {
    this.helpService.showPage(pageId);
  }

  private loadIcons(): void {
    // custom icons
    this.matIconRegistry.addSvgIcon(
      'xero',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/addOns/xero/xeroicon.svg')
    );
    // this.matIconRegistry.addSvgIcon(
    //   'logout',
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/logout.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   'login',
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/login.svg')
    // );
    this.matIconRegistry.addSvgIcon(
      'revert',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/revert.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'group',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/group.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ungroup',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/ungroup.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'horizontal_align_left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/horizontal_align_left-black-18dp.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'horizontal_align_center',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/horizontal_align_center-black-18dp.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'horizontal_align_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/horizontal_align_right-black-18dp.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'grid-snap',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/grid-snap.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'grid-size',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/grid-size.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'console',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/console.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rotate_0',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/designer/rotate_0.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rotate_90',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/designer/rotate_90.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rotate_180',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/designer/rotate_180.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rotate_270',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/designer/rotate_270.svg')
    );

  }
}
