import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelpPageEvent } from '../../services/help.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-help-popup',
  templateUrl: './help-popup.component.html',
  standalone: true,
  imports: [MaterialModule]
})
export class HelpPopupComponent {

  safeUrl: SafeResourceUrl;

  constructor(
    private dialogRef: MatDialogRef<HelpPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: HelpPageEvent,
    private sanitizer: DomSanitizer) {

    this.dialogRef?.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close();
      }
    });

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }
}
