import { Component, Input } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MaterialModule } from '../material.module';

@Component({
  selector: 'simple-progress',
  template: `
      @if (message) {
        <div fxLayoutAlign="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
          <mat-spinner [diameter]="diameter" [color]="color"></mat-spinner>
          <span class="mat-body-2">{{message}}&hellip;</span>
        </div>
      }`,
  standalone: true,
  imports: [MaterialModule, FlexModule]
})
export class SimpleProgressComponent {
  @Input()
  message: string;

  @Input()
  color: string = 'accent';

  @Input()
  diameter: number = 20;
}
