import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, filter, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { Query } from '../../../models/server/Query';
import { Table } from '../../../models/server/Table';
import { Policies } from '../../../models/server/User';
import { DataManagerService } from '../../../services/datamanager.service';
import { ActiveDataSourceService } from '../../services/active-data-source.service';
import { IDataSourceURLParams, IDataViewModel } from '../../services/query';
import { LocalStorageKeys as LocalStorageKeys } from '../../../models/server/Constants';
import { IsQueryValidPipe } from './is-query-valid.pipe';
import { AsyncPipe } from '@angular/common';
import { OrgNotDeactivatedDirective } from '../../../services/activeOrg.directive';
import { EditQueryComponent } from './edit-query/edit-query.component';
import { FormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout/flex';
import { MaterialModule } from '../../../material.module';

@Component({
    selector: 'app-query',
    templateUrl: './query.component.html',
    styleUrls: ['./query.component.scss'],
    standalone: true,
  imports: [
        MaterialModule,
        FlexModule,
        FormsModule,
        EditQueryComponent,
        OrgNotDeactivatedDirective,
        AsyncPipe,
        IsQueryValidPipe,
    ],
})
export class QueryComponent implements OnInit, OnDestroy {

  /**
   * A reference to the table which these queries belong to.
   */
  table?: Table;

  /**
   * Provides a way of unsubscribing from future data changes when this component
   * is destroyed. (Otherwise old queries will continue to execute after they're needed).
   */
  dataSourceSubscription: Subscription;

  public userCanViewAll: boolean;

  constructor(
    public dataSource: ActiveDataSourceService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private dataManager: DataManagerService) { }

  ngOnInit() {

    this.userCanViewAll = this.authService.hasPolicy(Policies.DatamanUpdateQuery);

    this.dataSourceSubscription = this.dataSource.table.pipe(
      /**
       * Only care about changes if the referenced table actually changed
       */
      filter((table : Table) => !!table),
      distinctUntilKeyChanged('id'),
      switchMap((table : Table) => {
        this.table = table;
        return this.dataManager.getQueryList(table.id, 0, 1000);
      }),
      tap(queries =>
        this.dataSource.queries = queries.values
          .filter(q => q.allowOperator || this.userCanViewAll)
          .map(q => q.params)),
      /**
       * Likewise, only care now if the query actually changes to an actual query or if
       * the variable predicates change in the URL.
       */
      switchMap(_ => combineLatest([this.dataSource.activeQuery, this.route.queryParamMap])),
    ).subscribe(([query, params]) => {
      // Now automatically open the respective query accordion panel, if none were previously open.
      const match = this.dataSource.queries.find(q => q.id === query.id);
      if (match) {
        match.isValid = true;

        // first time in, look for filter parameter values passed from the print dialog (probably)
        let forcedParams = localStorage.getItem(LocalStorageKeys.TransientQueryParams);
        let newParams;
        if (forcedParams) {
          try {
            newParams = JSON.parse(forcedParams);
            localStorage.removeItem(LocalStorageKeys.TransientQueryParams);
          } catch { }
        }

        this.dataSource.bindQueryParams(query, params.getAll('params'), newParams);

        if (this.dataSource.queries.some(q => q.active) === false) {
          match.active = true;
        }
      }
    });

    // Listen for the case of a table not being displayed, this means the sidebar must therefore
    // close as it can't query nothing
    this.dataSource.tables.pipe(
      filter(tables => tables.length === 0),
    )
      .subscribe(() => setTimeout(() => this.router.navigate(['..'], { relativeTo: this.route })));
  }

  ngOnDestroy() {
    this.dataSourceSubscription.unsubscribe();
  }


  /**
   * Executes the given query. This is achieved by changing the state of
   * the query parameters to include the query ID and respective parameters,
   * which then triggers the active data source to reload.
   * @param query The query to execute.
   */
  run(query: IDataViewModel<Query>) {

    const queryParams: IDataSourceURLParams = {
      table: this.table.id, query: query.id
    };

    // If the query requires runtime parameters, set these in the URL.
    if (query.predicates.some(predicate => !predicate.isImmutable)) {
      queryParams.params = query.predicates.filter(
        predicate => !predicate.isImmutable && predicate.value.new
      ).map(
        predicate => predicate.value.new
      );
    }

    query.isRunning = true;
    this.router.navigate(['.'], { relativeTo: this.route, queryParams });
  }

  getColumnName(name: string): string {
    switch (name.toLowerCase()) {
      case '__created':
        return 'Updated Date';
      case '__updatedbylogin':
        return 'Updated By';
      case '__updatedbyid':  // probably dont see this
        return 'Updated By';
    }

    return name;
  }

}
