import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogTitleComponent } from '../dialog-title/dialog-title.component';
import { MaterialModule } from '../../material.module';

export type LoginAction = () => void;

@Component({
  selector: 'app-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  standalone: true,
  imports: [MaterialModule, DialogTitleComponent]
})
export class SessionExpiredDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SessionExpiredDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public loginFunc: LoginAction
  ) { }

  ngOnInit() {
  }

  login(): void {
    this.dialogRef.close();
    this.loginFunc();
  }

}
