import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HelpService, HelpPopupStyle } from '../../services/help.service';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-help-icon',
  template: `
    @if (showPopups) {
      <button mat-icon-button style="height:42px" aria-label="Show Help" (click)="showHelp()" tabindex='-1'>
        <mat-icon>help</mat-icon>
      </button>
    }`,
  standalone: true,
  imports: [MaterialModule]
})
export class HelpIconComponent {


  // off in production
  showPopups = !environment.production;

  @Input()
  helpID: string;

  @Input()
  popupStyle: HelpPopupStyle = HelpPopupStyle.LargeRight;

  constructor(
    private helpService: HelpService) { }

  showHelp(): void {
    this.helpService.showPopup(this.helpID, this.popupStyle, null);
  }

}
