import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from "@angular/material/snack-bar";
import { FlexModule } from '@angular/flex-layout/flex';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-htmlsnackbar-content',
  templateUrl: './htmlsnackbar-content.component.html',
  styleUrls: ['./htmlsnackbar-content.component.scss'],
  standalone: true,
  imports: [MaterialModule, FlexModule]
})
export class HTMLSnackbarContentComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBar: MatSnackBar) {
  }

}
