import { NgModule } from '@angular/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MAT_CARD_CONFIG, MatCardConfig, MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, MatExpansionModule, MatExpansionPanelDefaultOptions } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule, MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

@NgModule({
  exports: [
    MatGridListModule, MatToolbarModule, MatButtonModule, MatMenuModule,
    MatDialogModule, MatIconModule, MatCardModule, MatPaginatorModule,
    MatChipsModule, MatListModule, MatExpansionModule, MatInputModule,
    MatRadioModule, MatSelectModule, MatCheckboxModule, MatProgressBarModule,
    MatFormFieldModule, MatTabsModule, MatTableModule, MatSliderModule,
    MatButtonToggleModule, MatSnackBarModule, MatProgressSpinnerModule,
    MatTooltipModule, MatSlideToggleModule, MatAutocompleteModule,
    MatStepperModule, MatBadgeModule, MatBottomSheetModule, MatSidenavModule,
    MatDividerModule, DragDropModule, MatDatepickerModule,
  ],
  providers: [
    //  { provide: MatDialogTitle, useValue: {} },
    // { provide: MatDialogRef, useValue: {} },
    //  { provide: MAT_DIALOG_DATA, useValue: [] }
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic', appearance: 'fill' } as MatFormFieldDefaultOptions },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { autoFocus: 'first-tabbable', restoreFocus: true, hasBackdrop: true, disableClose: true }
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } as MatSnackBarConfig },
    { provide: MAT_CARD_CONFIG, useValue: { appearance: "raised" } as MatCardConfig },
    // { provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, useValue: { appearance: "raised" }},
    { provide: MAT_TABS_CONFIG, useValue: { dynamicHeight: true, disablePagination: true } as MatTabsConfig },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MaterialModule {
}
