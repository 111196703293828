import { AuthorizationGuardService } from './authentication/authorization-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { ConfirmComponent } from './authentication/confirm/confirm.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { QueryComponent } from "./data/sidebars/query/query.component";

const routes: Routes = [
  {
    path: 'signin',
    component: SignInComponent,
  },
  {
    path: 'signup',
    component: SignUpComponent,
  },
  {
    path: 'confirm',
    component: ConfirmComponent,
  },
  {
    path: 'resetpass',
    component: ResetPasswordComponent,
  },
  {
    path: 'changepass',
    component: ChangePasswordComponent,
  },
  {
    path: 'design',
    loadComponent: () => import('./design-manager/open-design/open-design.component').then(m => m.OpenDesignComponent),
    canActivate: [AuthorizationGuardService],
    data: { permission: 'DesignView' }
  },
  {
    path: 'design/:id',
    loadComponent: () => import('./design/designer.component').then(m => m.DesignerComponent),
    canActivate: [AuthorizationGuardService],
    data: { permission: 'DesignView' }
  },
  {
    path: 'console',
    loadComponent: () => import('./console/console.component').then(m => m.ConsoleComponent),
    canActivate: [AuthorizationGuardService],
    data: { permission: 'ConsoleUse', isActiveOrganisation: true }
  },
  {
    path: 'console/:id',
    loadComponent: () => import('./console/console.component').then(m => m.ConsoleComponent),
    canActivate: [AuthorizationGuardService],
    data: { permission: 'ConsoleUse', isActiveOrganisation: true }
  },
  {
    path: 'library',
    loadComponent: () => import('./library/library.component').then(m => m.LibraryComponent),
    canActivate: [AuthorizationGuardService],
    data: { permission: 'LibraryView' }
  },
  {
    path: 'library/:id',
    loadComponent: () => import('./library/library.component').then(m => m.LibraryComponent),
    canActivate: [AuthorizationGuardService],
    data: { permission: 'LibraryView' }
  },
  {
    path: 'data',
    loadComponent: () => import('./data/data.component').then(m => m.DataComponent),
    canActivate: [AuthorizationGuardService],
    data: { permission: 'DatamanView' },
    children: [
      {
        path: 'queries',
        component: QueryComponent,
        canActivate: [],
        data: {
          sidebarTitle: 'Data Queries'
        }
      }
    ]
  },
  {
    path: 'settings',
    children : [
      {
        path: 'organisation',
        loadComponent: () => import('./settings/organisation-settings/organisation-settings.component').then(m => m.OrganisationSettingsComponent),
        canActivate: [AuthorizationGuardService],
        data: { permission: 'OrganisationView' },
      },

      {
        path: 'fonts',
        loadComponent: () => import('./settings/font-settings/font-settings.component').then(m => m.FontSettingsComponent),
      },
      {
        path: 'agents',
        loadComponent: () => import('./settings/agent-settings/agent-settings.component').then(m => m.AgentSettingsComponent),
      },
      {
        path: 'printers',
        loadComponent: () => import('./settings/printer-settings/printer-settings.component').then(m => m.PrinterSettingsComponent),
      },
      {
        path: 'serials',
        loadComponent: () => import('./settings/serial-settings/serial-settings.component').then(m => m.SerialSettingsComponent),
      },
      {
        path: 'variables',
        loadComponent: () => import('./settings/variable-settings/variable-settings.component').then(m => m.VariableSettingsComponent),
      },
      {
        path: 'addons',
        loadComponent: () => import('./settings/add-on-settings/add-on-settings.component').then(m => m.AddOnSettingsComponent),
      }
    ]
  },


  {
    path: 'addons',
    redirectTo: '/'
  },
  { path: '', component: HomeComponent },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
    //, { onSameUrlNavigation: 'reload' }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
