import { Component, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MaterialModule } from '../material.module';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  standalone: true,
  imports: [MaterialModule, FlexModule]
})
export class PagenotfoundComponent implements OnInit {

  image: string;
  title: string;

  private images = ['Joe1.gif', 'UFO1.gif', 'UFO2.jpg', 'Searching.gif'];
  private titles = [
    'Despite his best efforts, Joe could not find the page you requested',
    `Our support team are ready to help`,
    'Our support team are busy right now',
    'Our technical experts are out right now'];


  constructor() {
    const i = Math.trunc(Math.random() * 4 + 1);
    this.image = '/assets/404/' + this.images[i - 1];
    this.title = this.titles[i - 1];
  }

  ngOnInit() {

  }

}
