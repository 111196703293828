import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeedbackService } from '../../services/feedback.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerComponent } from '../../errorhandler.component';
import { SimpleProgressComponent } from '../simpleprogress.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { DialogTitleComponent } from '../dialog-title/dialog-title.component';
import { MaterialModule } from '../../material.module';

@Component({
  template: `
  <app-dialog-title [title]="title" icon="support_agent"></app-dialog-title>
  <div mat-dialog-content>
    <div class="mat-body-2">
      We love all feedback, happy or sad.
    </div>
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutGap="0.25rem">
        <mat-form-field class="wide">
          <mat-label>Message</mat-label>
          <textarea #comment matInput matAutosizeMinRows="4" matAutosizeMaxRows="8" maxResizeable="false"  formControlName="message"  >
          </textarea>
          <mat-error>Required</mat-error>
        </mat-form-field>

        <label>How do you feel?</label>
        <mat-radio-group formControlName="mood">
          <mat-radio-button value="verysad"><mat-icon>sentiment_very_satisfied</mat-icon></mat-radio-button>
          <mat-radio-button value="sad"><mat-icon>sentiment_satisfied</mat-icon></mat-radio-button>
          <mat-radio-button value="neutral"><mat-icon>sentiment_neutral</mat-icon></mat-radio-button>
          <mat-radio-button value="happy"><mat-icon>sentiment_dissatisfied</mat-icon></mat-radio-button>
          <mat-radio-button value="veryunhappy"><mat-icon>sentiment_very_dissatisfied</mat-icon></mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
  </div>
  <div mat-dialog-actions align="end">
    @if(sending) {
        <simple-progress message='Sending...'></simple-progress>
    }
    <button type="button" mat-raised-button mat-dialog-close>
      <mat-icon>cancel</mat-icon> Cancel
    </button>
    <button type="button" mat-raised-button color="primary" (click)="goAhead(true)" [disabled]="!form.dirty || !form.valid" >
      <mat-icon>email</mat-icon> Send It
    </button>
  </div>
  `,
  standalone: true,
  imports: [MaterialModule, DialogTitleComponent, FormsModule, ReactiveFormsModule, FlexModule, SimpleProgressComponent]
})
export class FeedbackDialogComponent {
  title = 'Feedback';
  sending = false;

  form: UntypedFormGroup;


  constructor(
    private dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private feedbackService: FeedbackService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: boolean) {

    this.dialogRef?.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close();
      }
    });

    this.form = this.fb.group({
      mood: ['neutral', Validators.required],
      message: ['', Validators.required]
    });
  }

  goAhead(response: boolean) {

    if (this.form.controls.message.value) {
      this.sending = true;
      const sub = this.feedbackService.postFeedback(this.form.controls.message.value, this.form.controls.mood.value).subscribe(_ => {
        this.snackBar.open('Thank you for your feedback. It has been sent to our support team who may contact you later if necessary.');
      },
        () => {
          this.sending = false;
          this.snackBar.open('Sadly, your feedback could not be sent at this time.  Please try again later.', 'Error', ErrorHandlerComponent.snackConfigErr);
        },
        () => {
          this.sending = false;
          sub.unsubscribe();
          this.dialogRef.close();
        });
    }

  }
}
